<div
  class="relative flex h-screen w-screen flex-col overflow-hidden bg-neutral90"
  (drop)="$event.preventDefault(); $event.stopPropagation()"
  (dragover)="$event.preventDefault(); $event.stopPropagation()"
>
  <div class="z-[900001] h-[56px] flex-shrink-0 bg-neutral90">
    <app-topbar></app-topbar>
  </div>
  <div class="flex min-h-0 flex-auto">
    <div class="z-[900001] w-[76px] flex-shrink-0 bg-neutral90">
      <app-leftmenu (event)="onEvent($event)"></app-leftmenu>
    </div>
    <div class="min-w-0 flex-auto pb-[8px] pe-[8px]">
      <router-outlet />
      <div
        class="absolute bottom-0 top-[56px] z-[900000] transition-[left]"
        [style.left]="(alertOpen ? 76 : -432) + 'px'"
      >
        <app-alert (event)="onEvent($event)"></app-alert>
      </div>
    </div>
  </div>
</div>

<div
  class="hidden bg-black bg-blue bg-brown bg-cyan bg-green bg-indigo bg-lightblue bg-lightred bg-mint bg-orange bg-pink bg-primary bg-purple bg-red bg-teal bg-yellow"
></div>
