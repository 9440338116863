<div class="flex h-full w-full flex-col items-center">
  <div class="flex h-[52px] flex-col items-center justify-center">
    <div class="my-[8px] h-[36px] w-[36px] overflow-hidden rounded-m">
      <app-profile
        [path]="client.client.profile"
        [name]="client.client.name"
      ></app-profile>
    </div>
  </div>
  <div
    class="group relative h-[74px] w-[36px] cursor-pointer"
    *ngFor="let menu of menus"
    (click)="moveMenu(menu, $event)"
    [routerLink]="menu.link !== '/alert' ? menu.link : '/blank'"
  >
    <div
      class="pointer-events-none mb-[4px] mt-[8px] flex h-[36px] w-[36px] items-center justify-center rounded-m group-hover:bg-neutral80"
      [routerLinkActive]="'hidden'"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(menu.icon)"
    ></div>
    <div
      class="pointer-events-none mb-[4px] mt-[8px] hidden h-[36px] w-[36px] items-center justify-center rounded-m bg-neutral80 group-hover:bg-neutral80"
      [routerLinkActive]="'flex_i'"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(menu.icon2)"
    ></div>
    <div
      class="pointer-events-none select-none text-center text-label-s text-neutral0"
    >
      {{ menu.name }}
    </div>
    <div
      class="absolute right-[8px] top-[10px] flex min-w-[16px] translate-x-1/2 select-none items-center justify-center rounded-l bg-primary px-[4px] py-[3px] text-center text-[10px] font-semibold leading-[10px] text-neutral100"
      *ngIf="menu.link === '/alert' && alertCount > 0"
    >
      {{ alertCount }}
    </div>
  </div>
</div>
