import { Component, inject } from '@angular/core';
import { ClientService } from './core/services/client.service';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { DomSanitizer } from '@angular/platform-browser';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'app';

  client = inject(ClientService);
  sanitizer = inject(DomSanitizer);

  constructor() {
    dayjs.locale('ko');
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advancedFormat);
    //dayjs.tz.setDefault('Asia/Seoul');

    console.log(environment.production);

    datadogRum.init({
      applicationId: '0f8ec4e3-087a-4807-9c0b-6e8f083d37cb',
      clientToken: 'pubfc64fa44e02dff275173436d071597df',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'findeet',
      env: environment.datadogEnv,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
      allowedTracingUrls: [/https?:\/\/(?:[^\/]*\.)?findeet\.io\/api/],
    });
  }

  isString(target: any) {
    return typeof target === 'string';
  }
}
