import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { HttpClientModule } from '@angular/common/http';
import { TopbarComponent } from './layouts/topbar/topbar.component';
import { LeftmenuComponent } from './layouts/leftmenu/leftmenu.component';
import { FormsModule } from '@angular/forms';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SharedModule } from './core/shared.module';
import { AlertComponent } from './pages/alert/alert.component';

import {
  provideLottieOptions,
  LottieComponent,
  LottieDirective,
} from 'ngx-lottie';
import { environment } from '../environments/environment';

const config: SocketIoConfig = {
  url: environment.socketUrl,
  options: {
    path: environment.socketPath,
    transports: ['websocket'],
    withCredentials: true,
  },
};

if (location.hostname !== 'findeet.io') {
  // config.url = 'http://' + location.hostname + ':9591/';
  // if (config.options) {
  //   config.options.path = '/socket.io/';
  // }
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutsComponent,
    TopbarComponent,
    LeftmenuComponent,
    AlertComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
