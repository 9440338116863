import { Component, inject } from '@angular/core';
import { ClientService } from '../../core/services/client.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent {
  client = inject(ClientService);
  router = inject(Router);

  profileMenu = false;

  logoutModal = false;
  logoutModalData = {
    id: 'logout',
    title: '로그아웃',
    titleIcon:
      '<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.59375 16.3203C5.57031 16.3203 4.97656 15.9688 4.41406 15.3438L1.41406 11.9766C0.851562 11.3438 0.710938 10.9297 0.710938 10.0703V6.64844C0.710938 5.78906 0.851562 5.38281 1.41406 4.74219L4.41406 1.38281C4.97656 0.757812 5.57031 0.398438 6.59375 0.398438H11.4062C12.4297 0.398438 13.0312 0.75 13.5859 1.38281L16.5234 4.74219C17.1016 5.41406 17.2891 5.78906 17.2891 6.64844V10.0703C17.2891 10.9297 17.1406 11.3438 16.5781 11.9766L13.5859 15.3438C13.0234 15.9688 12.4297 16.3203 11.4062 16.3203H6.59375ZM9.00781 9.63281C9.49219 9.63281 9.77344 9.35938 9.79688 8.85156L9.91406 5.16406C9.9375 4.64062 9.54688 4.26562 9 4.26562C8.44531 4.26562 8.0625 4.63281 8.08594 5.15625L8.20312 8.85156C8.22656 9.35938 8.50781 9.63281 9.00781 9.63281ZM9.00781 12.3828C9.57031 12.3828 10.0234 12 10.0234 11.4453C10.0234 10.8984 9.57812 10.5078 9.00781 10.5078C8.4375 10.5078 7.98438 10.8984 7.98438 11.4453C7.98438 11.9922 8.44531 12.3828 9.00781 12.3828Z" fill="#FF3B30"/></svg>',
    body: '로그아웃 하시겠어요?',
    buttonCancel: '취소하기',
    buttonSubmit: '로그아웃하기',
  };

  searchText = '';
  search = '';

  cb: any = {
    event: null,
  };

  ngOnInit() {
    this.cb.event = this.client.events.topbar.subscribe((e) => {
      console.log(e);
      if (e.id === 'clearSearch') {
        this.searchText = '';
        this.search = '';
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener('keydown', this.onKeyDown);
    try {
      this.cb.event.unsubscribe();
    } catch (e) {}
  }

  setClient(target: number) {
    this.client.interviewer.id = target;
    if (target === 1) {
      this.client.interviewer.name = '순조O';
      this.client.interviewer.level = 4;
      this.client.interviewer.id = 1;
    }
    if (target === 2) {
      this.client.interviewer.name = '순조SA';
      this.client.interviewer.level = 3;
      this.client.interviewer.id = 2;
    }
    if (target === 3) {
      this.client.interviewer.name = '순조A';
      this.client.interviewer.level = 2;
      this.client.interviewer.id = 3;
    }
    if (target === 4) {
      this.client.interviewer.name = '순조M';
      this.client.interviewer.level = 1;
      this.client.interviewer.id = 4;
    }
  }

  onKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      this.closeProfileMenu();
    }
  };

  toggleProfileMenu() {
    if (this.profileMenu) {
      this.closeProfileMenu();
    } else {
      this.openProfileMenu();
    }
  }

  openProfileMenu() {
    this.profileMenu = true;
    window.addEventListener('keydown', this.onKeyDown);
  }

  closeProfileMenu() {
    this.profileMenu = false;
    window.removeEventListener('keydown', this.onKeyDown);
  }

  logout() {
    localStorage.removeItem('token');
    location.href = '';
  }

  onPopupEvent(e: any) {
    if (e.id === 'logout') {
      this.logoutModal = false;
      if (e.data === 'submit') {
        this.logout();
      }
    }
  }
}
