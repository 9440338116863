<router-outlet />

<div
  class="
z-[800000]
absolute bottom-[24px] left-1/2 flex h-[36px] -translate-x-1/2 cursor-pointer select-none items-center gap-[4px] rounded-s bg-{{
    client.toast.bg
  }} pe-[16px] ps-[12px] text-body-m text-neutral100"
  *ngIf="client.toast && isString(client.toast.data)"
  [innerHTML]="
    sanitizer.bypassSecurityTrustHtml(client.toast.icon + client.toast.data)
  "
  (click)="client.toast.click()"
></div>
